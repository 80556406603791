<template>
  <v-layout align-start class="mt-1">
    <v-layout column class="default-style pa-3">
      <v-layout align-center v-if="field.card">
        <DefaultSubText color="primary" class="font-weight-bold">{{
          field.card && field.card.order > 0 ? `Wizyta ${field.card.order}` : "Wizyta niezakończona"
        }}</DefaultSubText>
        <v-spacer></v-spacer>
        <DefaultSubText color="#3c3c3c"
          >Data wizyty: {{ field.card.date_time }}
        </DefaultSubText>
        <DefaultSubText v-if="field.card.cancelled" color="error"
          >&nbsp;{{ `(Anulowano)` }}</DefaultSubText
        >
      </v-layout>
      <DefaultSubText
        v-else-if="field.edited_by"
        color="primary"
        class="font-weight-bold"
        >Autor: {{ field.edited_by }}</DefaultSubText
      >
      <slot></slot>
      <v-layout align-center>
        <DefaultSubText color="#3c3c3c" v-if="field.modified && field.ip"
          >Data dodania: {{ field.modified }}, ip:
          {{ field.ip }}</DefaultSubText
        >
        <v-spacer></v-spacer>
        <DefaultSubText class="reason" color="#3c3c3c" v-if="field.reason"
          >Edytowany. Powód: {{ field.reason }}.</DefaultSubText
        >
      </v-layout>
    </v-layout>
    <IconButton
      v-if="!hideEdit && !isSpectatorMode"
      @clicked="onEdit"
      icon="mdi-pencil"
      color="primary"
      bgcolor="transparent"
      class="ml-4 my-1"
      tooltip="Edycja wpisu"
      noYMargin
      noXMargin
    ></IconButton>
    <v-layout column v-if="isSpectatorMode" shrink>
      <IconButton
        v-if="appendableFields"
        @clicked="onAppend"
        icon="mdi-plus-circle"
        color="icons"
        bgcolor="transparent"
        class="ml-4 my-1"
        tooltip="Dodaj wpis do swojej karty pacjenta"
        noYMargin
        noXMargin
      ></IconButton>
      <IconButton
        ref="wrapper"
        v-if="copyableFields"
        @clicked="onCopy"
        icon="mdi-content-copy"
        color="icons"
        bgcolor="transparent"
        class="ml-4 my-1"
        tooltip="Kliknij by skopiować wpis do schowka"
        noYMargin
        noXMargin
      ></IconButton>
    </v-layout>
  </v-layout>
</template>

<script>
import { FIELD_TYPES } from "@/constants/patientcard/fields/types";
import { mapGetters } from "vuex";

export default {
  props: {
    field: {},
    session: {},
    owner: {},
    hideEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("ui", ["canUsePatientCards"]),
    isSpectatorMode() {
      return !!this.owner;
    },
    appendableFields() {
      return this.canUsePatientCards && [
        FIELD_TYPES.TEXT_FIELD,
        FIELD_TYPES.ICD9_CODES_FIELD,
        FIELD_TYPES.ICD10_CODES_FIELD,
        FIELD_TYPES.ICF_CODES_FIELD,
        FIELD_TYPES.DATE_FIELD,
      ].includes(this.field.field_type);
    },
    copyableFields() {
      return [FIELD_TYPES.TEXT_FIELD, FIELD_TYPES.DATE_FIELD].includes(
        this.field.field_type
      );
    },
  },
  components: {
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
    IconButton: () => import("@/components/buttons/IconButton"),
  },
  methods: {
    strip(html) {
      var tmp = document.createElement("div");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText;
    },
    onEdit() {
      this.$emit("edit");
    },
    onAppend() {
      this.$emit("append", this.field.data);
    },
    onCopy() {
      this.$emit("copy");
      if (this.field.field_type == FIELD_TYPES.DATE_FIELD) {
        return this.$copyText(
          this.strip(this.field.data.date),
          this.$refs.wrapper.$el
        );
      }
      return this.$copyText(
        this.strip(this.field.data.text),
        this.$refs.wrapper.$el
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.default-style {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px;
  background-color: white;
}
.reason {
  opacity: 0.5 !important;
}
</style>